<template>
  <div class="game" :style="{ height: dowBoxHeight}">
    <div class="top">
      <div class="header">
        {{$t('gameCenter.title')}}
      </div>
      
      <div class="centerWallet">
        <div class="centerWalletTop flexBetween">
          <div>{{$t('my.wallet.centerWallet')}}</div>
          <div>
            <img src="../../assets/img/gold.png">
            <span>{{ member && member.goldCoin ? numFormat(member.goldCoin) : '0.00' }}</span>
          </div>
        </div>
        <div class="centerWalletFunction">
          <div v-for="(item, index) in newListTab" :key="`${index}--listTab--game`" v-show="item.status" @click="$router.push(item.link)">
            <img v-real-img="item.iconUrl" />
            <p>{{ item.tagNameShow }}</p>
          </div>
        </div>
      </div>
    </div>
   
     <!-- 游戏展示 -->
     <div class="games" :style="{ height: gamesH}">
      <div class="left">
        <div v-for="(item, index) in gamesTitle" :key="`${index}--gamesTitle`" class="item" :class="gamesActive==item.id?'gamesActive':''" @click="getGames(item)">
          <img v-show="gamesActive==item.id" v-real-img="item.logo" />
          <img v-show="gamesActive!=item.id" v-real-img="item.icon" />
          <div class="fontGames">
            <p>{{ item.name }}</p>
          </div>
        </div>
      </div>
      <div class="right HomeGamesRight" >
        <div class="rightItem" v-for="(item, index) in games" :key="`${index}--sfdjfsdfd`" :class="`rightItem${index}`" >
          <div v-show="item.name != 'CQ9' && item.categoryId != 'lottery_tgcp'" class="HomeGamesRightTitle flexCenter"> <span></span> {{ item.name }} </div>
          <div v-for="(xItem, xIndex) in item.list" :key="`${xIndex}--gamesRight`" v-show="(item.showAll ? true : xIndex < item.number) && item.name != 'CQ9' && item.categoryId != 'lottery_tgcp'" @click="gameGo(xItem, item.categoryId)" class="item" :class="getParts > 1 ?  `item_${getParts}` : ''">
            <img class="bg" v-real-img="((item.needCollection && item.needCollection == '1') || item.categoryId == 20000 || item.categoryId == 'lottery_tgcp') ? xItem.icon : xItem.detail" :errorimg="require(`../../assets/img/errorImg/2.png`)"/>

            <img @click.stop="aditGameCollection(xItem)" v-show="item.needCollection =='1' && !xItem.isCollection" class="nollection" src="../../assets/img/game/noCollect.png" />
            <img @click.stop="aditGameCollection(xItem)" v-show="item.needCollection =='1' && xItem.isCollection" class="nollection" src="../../assets/img/game/isCollect.png" />
           
            <div class="gameName">
              {{ xItem.name }}
            </div>
          </div>

          <div v-show="(!item.showAll && item.list && item.list.length > item.number) && item.name != 'CQ9' && item.categoryId != 'lottery_tgcp'" @click="getMore(item, index)" class="gameMore">
            {{ $t('common.more') }}
            <img src="../../assets/img/down.png" />
          </div>

          <div v-show="item.showAll" @click="putAway(item, index)" class="gameMore">
            {{ $t('my.finance.putAway') }}
            <img src="../../assets/img/up.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {  startGame, gamePlatforms, getLabelsAndGameList, addGameCollection } from "@/api/index";
import jwt_decode from "jwt-decode";
import { mapState } from 'vuex';
import md5 from 'js-md5';
// import rechangeEntry from "@/pages/mixin/rechangeEntry"
import utils from '@/utils/index';
export default {
  // mixins: [rechangeEntry],
  name: "gameIndex",
  data() {
    return {
      gamesTitle: [],
      games:[],
      gamesActive: 0, //选中的游戏id
      isShowCont: false,
      active: 0,
      notice: [],
      gamesItemHeight: "",
      gamesLeftItemHeight: "",
      windowsVH: 0,
      gamesHeight: 0,
      listTab: [
        {
          icon: require('../../assets/img/game/recharge.png'),
          link: "/mine/finance/myDeposit",
          code: "recharge"
        },
        {
          icon: require('../../assets/img/game/withdraw.png'),
          link: "/mine/finance/withdraw",
          code: "withdraw"
        },
        {
          icon: require('../../assets/img/game/transfer.png'),
          link: "/mine/wallet",
          code: "transfer"
        },
        {
          icon: require('../../assets/img/game/record.png'),
          link: "/record/transaciton",
          code: "assetRecord"
        },
      ],
      showDialog: false
    }
  },
  computed: {
    ...mapState(['member', 'systemConfig', 'lotteryRouter', 'gameType', 'isLoadForLoad', 'scrollHeight', 'showDownApp', 'isVest']),
    isLogin() {
      return this.$store.getters.isLogin
    },
    getParts() {
      let index = this.gamesTitle.findIndex(item => {
        return item.id == this.gamesActive;
      });

      if (index != -1) {
        return this.gamesTitle[index].remark;
      }

      return "1";
    },
    newListTab() {
      let listTab = JSON.parse(JSON.stringify(this.listTab)), newListTab = [];
      if (this.systemConfig && this.systemConfig.length > 0) {
        let index = this.systemConfig.findIndex(item => {
          return item.code == 'game';
        });
        if (index != -1 && this.systemConfig[index] && this.systemConfig[index].systemConfigTagList) {
          let oldArr = JSON.parse(JSON.stringify(this.systemConfig[index].systemConfigTagList)) || [];
          listTab.forEach(xitem => {
            oldArr.forEach((item, index) => {
              if (xitem.code == item.code) {
                let obj = {...xitem, ...item};
                newListTab.push(obj);
              }
            });
          });
        }
      }

      newListTab = newListTab.sort(function (a, b) {
        return (a.tagSort - b.tagSort);
      });

      return newListTab;
    },
    dowBoxHeight() {
      let height = window.innerHeight;
      if (this.showDownApp) {
        height = `calc(${height}px - 1.2rem)`
      }

      return height;
    },
    gamesH() {
      let height = this.gamesHeight+'px';
      if (this.showDownApp) {
        height = `calc(${this.gamesHeight}px - 1rem)`
      }
      return height;
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   next((e) => {
  //     if (e.gamesTitle.length > 0 && e.gameType) {
  //       let index = e.gamesTitle.findIndex(item => {
  //         return item.remark == e.gameType || item.thirdConfig == e.gameType;
  //       });

  //       if (index != -1 && e.gamesTitle[index].id != e.gamesActive) {
  //         e.getGames(e.gamesTitle[index]);
  //       }

  //       console.log(index);
  //       if (index == -1) {
  //         e.getGames(e.gamesTitle[0]);
  //       }
  //     }
  //     e.$store.commit("setState", { gameType: ""});
  //   });
  // },
  watch: {
    $route(to, from) {
      if (to.path === '/game') {
        this.$store.commit("setState", { entry: '/game' });

        if (this.gamesTitle.length > 0 && this.gameType) {
          let index = this.gamesTitle.findIndex(item => {
            return item.remark == this.gameType || item.thirdConfig == this.gameType;
          });

          if (index != -1 && this.gamesTitle[index].id != this.gamesActive) {
            this.getGames(this.gamesTitle[index]);
          }

          if (index == -1) {
            this.getGames(e.gamesTitle[0]);
          }
        }
        this.$store.commit("setState", { gameType: ""});
      }
    }
  },
  created() {
    this.$store.commit("setState", {entry: this.$route.path});
    this.getGameList();

    if (this.isLogin) {
      this.$store.dispatch('refreshUserInfo');
    }
  },
  mounted() {
    this.safariHacks();
  },
  methods: {
    // 点击更多
    getMore(item, index) {
      let scrollTop = document.getElementsByClassName(`HomeGamesRight`)[0].scrollTop;
      let obj = JSON.parse(JSON.stringify(this.games[index]));
      obj.scrollTop = scrollTop; 
      obj.showAll = !item.showAll;
      this.games[index] = JSON.parse(JSON.stringify(obj));
    },
    // 收起
    putAway(item, index) {
      let obj = JSON.parse(JSON.stringify(this.games[index]));
      obj.showAll = !item.showAll;
      this.games[index] = JSON.parse(JSON.stringify(obj));

      this.games.forEach((xitem, xindex) => {
        if (xitem.scrollTop && xindex > index && item.showAll) {
          let max = document.getElementsByClassName(`HomeGamesRight`)[0].scrollTop,
            min = obj.scrollTop || 0,
            gap = max - min;
            xitem.scrollTop = xitem.scrollTop - gap;
        }
      })

      document.getElementsByClassName(`HomeGamesRight`)[0].scrollTop = obj.scrollTop || 0;
    },
    // 添加-取消游戏收藏 addGameCollection
    aditGameCollection(item) {
      if (this.isLoadForLoad) return;
      let params = {
        gameId: item.gameId,
        isCollection: item.isCollection ? 0 : 1,
        type: item.type,
        uid: this.member.uid
      }
      this.$store.commit("setState", { isLoadForLoad: true });
      // 收藏 取消收藏
      addGameCollection(params).then(res => {
        if (res.code === 0 || res.code === '0') {
          let listParams = {
            id: this.gamesActive
          }
          this.getGames(listParams);

          let msg = params.isCollection ? this.$t('gameCenter.addGameCollectionTips') : this.$t('gameCenter.cancelGameCollectionTips');
          this.$toast(msg);
        } else {
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(() => {
      });
    },
    //轮播图点击跳转
    // bannerItem(item, index) {
    //   // openWay 0 站内 1 站外
    //   if (item.jumpUrl) {
    //     if (!item.openWay) {
    //       this.$store.commit("setState", {bannerDetail: item});
    //       this.$router.push(`/home/bannerDetail/game${index}`);
    //     } else {
    //       window.open(item.jumpUrl);
    //       // window.location.href = item.jumpUrl;
    //     }
    //   }
    // },
    // 界面下方高度
    safariHacks() {
      let windowsVH = this.windowsVH = window.innerHeight,
          topH = document.getElementsByClassName('top')[0].offsetHeight,
          footerH = document.getElementsByClassName('footer')[0].offsetHeight;
        if (!footerH) {
          footerH = 54;
        }
        this.gamesHeight = windowsVH - footerH - topH - 20;
    },
    startGame(item) {
      if (sessionStorage.getItem('token')) {
        let uid = localStorage.getItem('uid') || utils.guid(),
        timestamp = new Date().getTime(),
        params = {
          os: 0,
          sign: md5(`${uid}jgyh,kasd${timestamp}`),
          timestamp: timestamp,
          udid: localStorage.getItem('uid') || utils.guid(),
          gameId: item.gameId,
          gamePlatform: item.name,
          uid: this.member.uid,
          id: item.id
        }
        
        this.$store.commit("setState", { isLoadForLoad: true });
        startGame(params).then(res => {
            if (res.code === 0 || res.code === '0') {
              if (res.data.gamePlatform === 'horizontal') {
                this.$store.commit("setState", { isShowDownLoadApp: true });
              } else if(res.data.gamePlatform === 'html') {
                if (this.isVest) {
                  window.webkit.messageHandlers.iosHandler.postMessage({ type: 'html', conten: res.data.forwardUrl });
                } else {
                  const userAgent = navigator.userAgent.toLowerCase();
                  if (userAgent.includes('safari')) {
                    this.$dialog.confirm({
                      message: `${this.$t('gameCenter.GoToGame')}`,
                      showCancelButton: true,
                      confirmButtonText: `${this.$t('common.confirm')}`,
                      cancelButtonText:  `${this.$t('common.cancel')}`,
                      closeOnClickOverlay: true,
                      className: "confirmDialog",
                      confirmButtonColor: "#eb457e"
                    })
                    .then(() => {
                      let newPage = window.open('', '_blank');
                      newPage.document.open();
                      newPage.document.write(res.data.forwardUrl);
                      newPage.document.close();
                    })
                    .catch(() => {
                        // on cancel
                    });
                  } else {
                    let newPage = window.open('', '_blank');
                    newPage.document.open();
                    newPage.document.write(res.data.forwardUrl);
                    newPage.document.close(); 
                  }
                }
              } else {
                if (res.data.forwardUrl) {
                  if (this.isVest) {
                    window.webkit.messageHandlers.iosHandler.postMessage({ type: 'url', conten: res.data.forwardUrl });
                  } else {
                    window.location.href = res.data.forwardUrl;
                  }
                } else {
                  this.$toast(this.$t('gameCenter.gameAbnormality'))
                }
              }
            } else {
              if (res.msg) this.$toast(res.msg);
            }

            this.$store.commit("setState", { isLoadForLoad: false });
        }).catch(() => {
            console.log('catch');
            this.$store.commit("setState", { isLoadForLoad: false });
        });
      } else {
        this.$store.commit("setState", { member: null });
        this.$toast(this.$t('home.noLogin'));
        this.$router.push("/login");
      }
    },
    // 国家切换
    cuntryClick(item, index) {
      this.countryActive = index;
    },
    // 货币切换
    currencyClick(item, index) {
      this.currencyActive = index;
    },
    // 最下边的右侧列表点击事件
    gameGo(item, categoryId) {
      if (sessionStorage.getItem('token')) {
        if (categoryId && (categoryId == 20000 || categoryId == 'lottery_tgcp')) {
          this.$router.push(`/lottery/${item.gameId}`);
        } else {
          this.startGame(item);
        }
      } else {
        this.$store.commit("setState", { member: null });
        this.$router.push("/login");
      }
    },
    // 最下边的左侧列表数据获取
    getGameList() {
      let uid="";
      if (sessionStorage.getItem('token')) {
        let userInfo = jwt_decode(sessionStorage.getItem('token'));
        uid = userInfo.userId;
      }
      gamePlatforms(uid).then(res => {
        if (res.code === 0 || res.code === '0') {
          this.gamesTitle = res.data;
          let activeData = this.gamesTitle[0];

          let gameType = JSON.parse(JSON.stringify(this.gameType));

          if (gameType) {
            let index = this.gamesTitle.findIndex(item => {
              return item.remark == gameType || item.thirdConfig == gameType;
            });

            if (index != -1) {
              activeData = this.gamesTitle[index];
            }
          }
          this.getGames(activeData);
          this.$store.commit("setState", { gameType: ""});
        } else {
          this.gamesTitle = [];
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(() => {
        this.gamesTitle = [];
        console.log('catch');
      });
    },
    // 最下边的右侧列表数据获取
    getGames(item) {
      this.$store.commit("setState", { isLoadForLoad: true })
      this.gamesActive = item.id;
      this.games = [];
      getLabelsAndGameList(item.id).then(res => {
        if (res.code === 0 || res.code === '0') {
          // this.games = res.data;
          let games = res.data;
          games.forEach(item => {
            item.list = item.list.filter(xitem => {
              return xitem.gameId != 'tgcp_system' && xitem.gameId != 'tgcp_official';
            })
            this.games.push(item);
          })

        } else {
          if (res.msg) this.$toast(res.msg);
        }
        if (this.isLoadForLoad) this.$store.commit("setState", { isLoadForLoad: false });
      }).catch(() => {
        if (this.isLoadForLoad)  this.$store.commit("setState", { isLoadForLoad: false });
        console.log('catch');
      });
    },
    // 路由跳转
    goOtherPage(path) {
      if (!this.isLogin) {
        this.$router.push("/login");
      } else {
        this.$router.push(path);
      }
    },
    // 文字滚动点击事件
    showCont(item) {
      this.$dialog({
        // title: this.$t('common.hint'),
        message: item.content,
        className: "confirmDialog",
        confirmButtonText: this.$t('common.cancel'),
        confirmButtonColor: "#eb457e"
      }).then(() => {
      })
      // .catch(() => {
      //   console.log("取消");
      // });
    }
  },
  beforeRouteEnter(to, from, next) {
    next((e) => {
      if (e.scrollHeight && e.scrollHeight.game && document.getElementsByClassName('HomeGamesRight')[0]) {
        document.getElementsByClassName('HomeGamesRight')[0].scrollTop = e.scrollHeight.game;
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    let scrollHeight = JSON.parse(JSON.stringify(this.scrollHeight));
    scrollHeight.game = document.getElementsByClassName('HomeGamesRight')[0].scrollTop;
    this.$store.commit("setState", { scrollHeight });
    next();
  },
}
</script>
<style lang="scss" scoped>
.game {
  overflow: hidden;
  padding-bottom: 0.96rem;
  background-color: #f9f9f9;

  .top {
    position: relative;
    .header {
      height: 0.88rem;
      padding: 0.28rem 0.28rem 0;
      background: -webkit-linear-gradient(left,#f86fab,#fa8faf);
      background: linear-gradient(90deg,#f86fab,#fa8faf);
      color: #fff;
      font-size: 0.32rem;
    }

    .centerWallet {
      margin: 0.2rem auto 0;
      width: calc(100vw - 0.56rem);
      height: 2.88rem;
      // background-image: url("../../assets/img/game/bg.png");
      // background-size: cover;
      // background-repeat: no-repeat;
      font-size: 0.24rem;
      padding-top: 0.2rem;
      background-color: #fff;
      box-shadow: 0 0 0.1rem 0 #e5e5e5;
      border-radius: 0.2rem;

      .centerWalletTop {
        font-size: 0.32rem;
        color: #333;
        padding: 0.2rem 0.4rem 0.38rem;

        span {
          font-size: 0.4rem;
          font-weight: bold;
        }

        img {
          width: 0.32rem;
          height: 0.32rem;
          margin-right: 0.1rem;
        }
      }

      .centerWalletFunction {
        display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
        display: -moz-box; /* Firefox 17- */
        display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
        display: -moz-flex; /* Firefox 18+ */
        display: -ms-flexbox; /* IE 10 */
        display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
        
        div {
          flex: 1;
          text-align: center;
          color: #333;

          img {
            width: 0.64rem;
            height: 0.64rem;
            margin-bottom: 0.2rem;
          }

        }
      }
    }
  }

  .changeApp {
    padding: 0 0.28rem;

    div {
      width: calc(50% - 0.1rem);
      background-color: #edeeef;
      box-shadow: 0 0.08rem 0.1rem rgba(65, 70, 92, 0.2);
      height: 0.88rem;
      line-height: 0.88rem;
      font-size: 0.31rem;
      border-radius: 0.1rem;
      color: #788597;

      &:first-child {
        background-color: #58a2fd;
        box-shadow: 0 0.08rem 0.1rem rgba(35, 142, 255, 0.3);
        color: #fff;
      }
    }
  }

  .games {
    padding: 0 0.28rem;
    display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
    display: -moz-box; /* Firefox 17- */
    display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
    display: -moz-flex; /* Firefox 18+ */
    display: -ms-flexbox; /* IE 10 */
    display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */

    -webkit-justify-content: space-between;
    justify-content: space-between;
    -moz-box-pack: space-between;
    -webkit-moz-box-pack: space-between;
    box-pack: space-between;
    text-align: left;
    margin-top: 0.2rem;

    .left {
      font-size: 0.2rem;
      margin-right: 0.3rem;
      min-height: 4rem;
      // max-height: 7.92rem;
      overflow-x: hidden;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;

      /* 隐藏滚动条 */
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */

      &::-webkit-scrollbar {
        width: 0;      /* Safari,Chrome 隐藏滚动条 */
        height: 0;     /* Safari,Chrome 隐藏滚动条 */
        display: none; /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
      }


      .item {
        width: 1.36rem;
        min-height: 1.2rem;
        color: #666;
        font-size: 0.2rem;
        height: 1.2rem;
        text-align: center;
        background: -webkit-linear-gradient(#fff,#fff, #fff1f7);
        background: linear-gradient(#fff,#fff, #fff1f7);
        box-shadow: 0px 0px 1px 0px #eb457e;
        -webkit-box-shadow: 0px 0px 1px 0px #eb457e;
        margin-bottom: 0.2rem;
        border-radius: 0.15rem;

        .fontGames {
          padding: 0 0.1rem;
          p{
            width: 100%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: clip;
            font-size: 0.2rem;
            margin-left: 0.05rem;
            text-align: center;
            padding-right: 0.1rem;
          }
        }


        &.gamesActive {
          color: #fff;
          padding-top: 0;
          background: -webkit-linear-gradient(left,#f86fab,#fa8faf);
          background: linear-gradient(90deg,#f86fab,#fa8faf);
          box-shadow: 0px 0px 1px 0px #eb457e;
          -webkit-box-shadow: 0px 0px 1px 0px #eb457e;

          .fontGames p {
            margin-top: -0.1rem;
          }
        }

        img {
          width: 0.52rem;
          margin-left: 0.1rem;
          margin: 0.16rem 0 0.05rem; 
          object-fit: cover;
        }
      }
    }

    .right {
      width: 5.59rem;
      min-height: 4rem;
      // max-height: 7.92rem;
      overflow-x: hidden;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      /* 隐藏滚动条 */
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */

      &::-webkit-scrollbar {
        width: 0;      /* Safari,Chrome 隐藏滚动条 */
        height: 0;     /* Safari,Chrome 隐藏滚动条 */
        display: none; /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
      }

      .HomeGamesRightTitle {
        font-size: 0.28rem;
        margin-bottom: 0.2rem;
        height: 0.28rem;

        span {
          display: inline-block;
          width: 0.05rem;
          height: 0.28rem;
          background-color: #f770ab;
          margin: 0.08rem 0.1rem 0 0;
        }
      }

      .item {
        // box-shadow: 0 0.04rem 0.19rem 0 rgba(187, 197, 240, 0.5);
        position: relative;
        overflow: hidden;
        border-radius: 0.1rem;
        height: 3rem;
        margin-bottom: 0.2rem;
        display: inline-block;
        width: 100%;

        .bg {
          width: 100%;
          height: 2.4rem;
          display: block;
          object-fit: cover;
        }

        .nollection {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1;
        }

        .official {
          width: 0.74rem;
          height: 0.74rem;
          position: absolute;
          top: 0.04rem;
          left: 0.04rem;
        }

        .gameList_item {
          width: 2.68rem;
          height: 1.44rem;
          top: 0.6rem;
          left: 0.4rem;
          position: absolute;
          font-size: 0.35rem;
          text-align: left;
          font-size: 0.36rem;
          color: #687382;
        }

        &.item_2 {
            width: calc(50% - 0.1rem);
            height: 2.16rem;
            margin-right: 0.2rem;

            &:nth-child(2n - 1) {
              margin-right: 0;
            }

            .bg {
              height: 1.64rem;
            }
          }

          &.item_3 {
            width: calc(33.3% - 0.16rem);
            height: 1.9rem;
            display: inline-block;
            margin-right: 0.2rem;

            &:nth-child(3n+1) {
              margin-right: 0;
            }

            .bg {
              height: 1.32rem;
            }

            .gameName {
              margin-bottom: 0.05rem;
              height: 0.56rem;
              display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
              display: -moz-box; /* Firefox 17- */
              display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
              display: -moz-flex; /* Firefox 18+ */
              display: -ms-flexbox; /* IE 10 */
              display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
            
              align-items:center;
              -webkit-align-items:center;
              box-align:center;
              -moz-box-align:center;
              -webkit-box-align:center;

              -webkit-justify-content: center;
              justify-content: center;
              -moz-box-pack: center;
              -webkit-moz-box-pack: center;
              box-pack: center;
            }
          }

        .gameName {
          position: absolute;
          bottom: 0;
          left: 0;
          text-align: center;
          font-size: 0.24rem;
          color: #788597;
          width: 100%;
          margin-bottom: 0.15rem;
          z-index: 6;
        }
      }
    }
  }

  .rightItem {
    position: relative;
    padding-bottom: 0.4rem;

    .gameMore {
      position: absolute;
      right: 0.28rem;
      bottom: 0.15rem;
      font-size: 0.24rem;
      color: #788597;

      img {
        width: 0.2rem;
      }
    }
  }

  .openBg {
    position: fixed;
    right: 0.28rem;
    bottom: 1.8rem;
    width: 0.48rem;
    z-index: 10;
  }
}
</style>
<style lang="scss">
.game {
  .van-swipe__indicators {
    bottom: 0.5rem;
  }

  .van-swipe__indicator {
    background-color: #fff;
    opacity: 0.8;
  }

  .van-swipe__indicator--active {
    background-color: #7B95FA;
    width: 0.16rem;
  }
}
</style>